/* eslint-disable no-console */
import { datadogLogs, Datacenter } from '@datadog/browser-logs'
import { exists } from '~/types/maybe'

const clientToken = process.env.DD_CLIENT_TOKEN
const noop = () => null
let logger: typeof datadogLogs.logger

if (exists(clientToken) && clientToken.length) {
  datadogLogs.init({
    clientToken,
    datacenter: Datacenter.US,
    forwardErrorsToLogs: true,
    sampleRate: 100,
  })

  // TODO: rename env var to decouple from sentry
  datadogLogs.logger.addContext(
    'ENVIRONMENT',
    process.env.SENTRY_ENVIRONMENT ?? 'UNKNOWN',
  )

  logger = datadogLogs.logger
} else {
  logger = {
    debug: console.debug.bind(console),
    error: console.error.bind(console),
    info: console.info.bind(console),
    log: console.log.bind(console),
    warn: console.warn.bind(console),

    addContext: noop,
    setContext: noop,
    setHandler: noop,
    setLevel: noop,
  }
}

export default datadogLogs.logger
